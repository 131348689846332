import React from "react"
import { Fragment } from "react"
import RxIndicator from "elements/RxIndicator"
import { generatePrice } from "../../Epharmacy/services/computations"

const BrandTag = ({ values }) => {
  if (Array.isArray(values) && values.length > 0) {
    return (
      <Fragment>
        <span className="helper">
          Sample Brands: {values.map((value) => value.Brand[0]).join(", ")}
        </span>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <span className="helper">Sample Brand: {values.Brand[0]}</span>
      </Fragment>
    )
  }
}

const SearchResultRow = ({ result, handleClick, module }) => {
  // choose which drug name to display
  let drugName =
    module === "flexmedCashless" || module === "flexmed"
      ? result?.productTitle
      : result?.DrugName

  return (
    <li>
      <div
        onClick={() => handleClick()}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleClick()
        }}
      >
        <div className="is-flex has-text-weight-bold mb-1">
          <div className="has-text-left ">{drugName}</div>
          <RxIndicator />
        </div>
        {result?.values && <BrandTag values={result?.values} />}
        {!!parseInt(generatePrice(result)) && (
          <div className="helper mt-1">Php {generatePrice(result)}</div>
        )}
      </div>
    </li>
  )
}

export default SearchResultRow
