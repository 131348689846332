import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { fuzzySearch, groupBy } from "./services/fuzzySearch"
import SearchResults from "./SearchResults"
import RefillSearchResults from "./RefillSearchResults"
import Message from "../Elements/Message"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  dataSource,
  onSelect,
  field = {},
  withIcon,
  handleChange,
  isMobile,
  module,
  helper,
  message,
  id,
  searchType,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isOptionHidden, setOptionHidden] = useState(true)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              searchResults = groupBy(searchResults, "DrugName")
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        case "flexmedCashless":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        case "flexmed":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )
              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        case "json":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              let searchResults = await fuzzySearch(
                searchQuery,
                dataSource.data,
                dataSource.lookupKeys
              )

              setSearchResults(searchResults)
              setIsLoading(false)
            }, 1000)
          )
          break
        default:
          break
      }
    }
    //eslint-disable-next-line
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    if (module === "flexmedCashless" || module === "flexmed") {
      let values = searchResults[0]
      let distance = Array.isArray(values)
        ? values[0]?.distance
        : values?.distance
      return searchResults.length > 0 && distance !== 1
    } else {
      let values = searchResults[0]?.values
      let distance = Array.isArray(values)
        ? values[0]?.distance
        : values?.distance
      return searchResults.length > 0 && distance !== 1
    }
  }

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") setOptionHidden(true)
  }

  return (
    <Fragment>
      <div
        onFocusOut={() => {
          setOptionHidden(true)
        }}
        tabIndex="-1"
      >
        <div className="field">
          {!!label && (
            <label
              className={classNames("label", {
                "has-text-weight-normal has-text-grey-dark": !isRequired,
              })}
            >
              {label}
              {!!isRequired && <span className="has-text-danger"> *</span>}
              {!!helper?.message && (
                <span
                  className={classNames(
                    "helper has-text-weight-normal",
                    helper?.className || ""
                  )}
                >
                  {helper?.message}
                </span>
              )}
            </label>
          )}
          <div className="field-body">
            <div className="field">
              <p
                className={classNames("control has-icons-left", {
                  "is-loading": isLoading,
                  " has-icons-left": withIcon,
                })}
              >
                <input
                  type={type}
                  className="input"
                  name={name}
                  placeholder={placeholder}
                  {...field}
                  onChange={(event) => {
                    if (field.onChange) field.onChange(event)
                    if (isOptionHidden) setOptionHidden(false)
                    if (handleChange) handleChange(event)
                    // if (onSelect) onSelect(null)
                    setSearchQuery(event.target.value)
                  }}
                  onKeyDown={handleOnKeyDown}
                  readOnly={isMobile}
                  // value={value || searchQuery}
                  id={id}
                  aria-label="search"
                />
                {withIcon && (
                  <span className={classNames("icon is-small is-left")}>
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
        {!isOptionHidden && searchType !== "drug" && (
          <SearchResults
            searchResults={searchResults}
            isExactMatch={handleValidateMatchDistance()}
            onSelect={onSelect}
            setSearchResults={setSearchResults}
            setSearchQuery={setSearchQuery}
            module={module}
          />
        )}
        {!isOptionHidden && searchType === "drug" && (
          <RefillSearchResults
            searchQuery={searchQuery}
            searchType={searchType}
            searchResults={searchResults}
            isExactMatch={handleValidateMatchDistance()}
            onSelect={onSelect}
            setSearchResults={setSearchResults}
          />
        )}
        {!!message?.content ? (
          <Message color={message?.color} className="mt-1">
            {message?.content}
          </Message>
        ) : null}
      </div>
    </Fragment>
  )
}

export default Search
