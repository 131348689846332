// import { max } from "moment"
// import lodash from "lodash"
// import _ from "lodash"

export const getEditDistance = (
  lookupString,
  resultString,
  lookupStringLength,
  resultStringLength
) => {
  var computationMatrix = []
  for (let i = 0; i <= lookupStringLength; i++) {
    computationMatrix[i] = []
    for (let j = 0; j <= resultStringLength; j++) {
      computationMatrix[i][j] = 999
    }
  }

  for (let i = 0; i <= lookupStringLength; i++) {
    for (let j = 0; j <= resultStringLength; j++) {
      if (i === 0) computationMatrix[i][j] = j
      else if (j === 0) computationMatrix[i][j] = i
      else if (lookupString[i - 1] === resultString[j - 1])
        computationMatrix[i][j] = computationMatrix[i - 1][j - 1]
      else
        computationMatrix[i][j] =
          1 +
          Math.min(
            computationMatrix[i][j - 1], // Insert
            computationMatrix[i - 1][j], // Remove
            computationMatrix[i - 1][j - 1]
          ) // Replace
    }
  }

  return computationMatrix[lookupStringLength][resultStringLength]
}

export const fuzzySearch = (lookupString, dataSource, lookupKeys) => {
  for (let i = 0; i < dataSource.length; i++) {
    dataSource[i].distance = null
  }

  for (let i = 0; i < lookupKeys.length; i++) {
    for (let j = 0; j < dataSource.length; j++) {
      let currentStringList = dataSource[j][lookupKeys[i]][0]
      if (currentStringList) {
        let tempLookupString = lookupString.toLowerCase()

        let tempResultString = currentStringList.toLowerCase() // here
        let maxLength = Math.max(
          tempLookupString.length,
          tempResultString.length
        )

        let tempDistance = tempResultString.includes(tempLookupString)
          ? 1
          : (maxLength -
              getEditDistance(
                tempLookupString,
                tempResultString,
                tempLookupString.length,
                tempResultString.length
              )) /
            maxLength

        if (dataSource[j].distance)
          if (tempDistance > dataSource[j].distance) {
            dataSource[j].distance = tempDistance
            dataSource[j].closestMatch = lookupKeys[i]
          } else continue
        else {
          dataSource[j].distance = tempDistance
          dataSource[j].closestMatch = lookupKeys[i]
        }
      } else {
        dataSource[j].distance = -999
        dataSource[j].closestMatch = lookupKeys[i]
      }
    }
  }

  let sortedData = dataSource.sort((a, b) =>
    b.distance - a.distance === 0
      ? a[a.closestMatch][0].toLowerCase().indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch][0]
            .toLowerCase()
            .indexOf(lookupString.toLowerCase()) ===
        0
        ? a[a.closestMatch][0].length - b[b.closestMatch][0].length
        : a[a.closestMatch][0]
            .toLowerCase()
            .indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch][0].toLowerCase().indexOf(lookupString.toLowerCase())
      : b.distance - a.distance
  )

  return sortedData
}

export const refillsFuzzySearch = (lookupString, dataSource, lookupKeys) => {
  for (let i = 0; i < dataSource.length; i++) {
    dataSource[i].distance = null
  }

  for (let i = 0; i < lookupKeys.length; i++) {
    for (let j = 0; j < dataSource.length; j++) {
      if (dataSource[j][lookupKeys[i]]) {
        let tempLookupString = lookupString.toLowerCase()
        // goes through each brand of the datamap
        let tempResultString = dataSource[j][lookupKeys[i]].toLowerCase()
        let maxLength = Math.max(
          tempLookupString.length,
          tempResultString.length
        )

        let tempDistance = tempResultString.includes(tempLookupString)
          ? 1
          : (maxLength -
              getEditDistance(
                tempLookupString,
                tempResultString,
                tempLookupString.length,
                tempResultString.length
              )) /
            maxLength

        if (dataSource[j].distance)
          if (tempDistance > dataSource[j].distance) {
            dataSource[j].distance = tempDistance
            dataSource[j].closestMatch = lookupKeys[i]
          } else continue
        else {
          dataSource[j].distance = tempDistance
          dataSource[j].closestMatch = lookupKeys[i]
        }
      } else {
        dataSource[j].distance = -999
        dataSource[j].closestMatch = lookupKeys[i]
      }
    }
  }

  let sortedData = dataSource.sort((a, b) =>
    b.distance - a.distance === 0
      ? a[a.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch]
            .toLowerCase()
            .indexOf(lookupString.toLowerCase()) ===
        0
        ? a[a.closestMatch].length - b[b.closestMatch].length
        : a[a.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase()) -
          b[b.closestMatch].toLowerCase().indexOf(lookupString.toLowerCase())
      : b.distance - a.distance
  )

  return sortedData
}

export const getMaxTempDistance = (
  tempLookupString,
  currentStringList,
  dataSource,
  lookupKeys
) => {
  let distances = []

  currentStringList
    .map((string) => string.toLowerCase())
    .forEach((tempResultString) => {
      let maxLength = Math.max(tempLookupString.length, tempResultString.length)

      let tempDistance = tempResultString.includes(tempLookupString)
        ? 1
        : (maxLength -
            getEditDistance(
              tempLookupString,
              tempResultString,
              tempLookupString.length,
              tempResultString.length
            )) /
          maxLength

      if (dataSource.distance)
        if (tempDistance > dataSource.distance) {
          dataSource.distance = tempDistance
          dataSource.closestMatch = lookupKeys
        } else return
      else {
        dataSource.distance = tempDistance
        dataSource.closestMatch = lookupKeys
      }

      distances.push({ string: tempResultString, distance: tempDistance })
    })

  return dataSource

  // const max = distances.reduce((a, b) => (a.distance > b.distance ? a : b))
  // return max.distance
}

export const groupBy = (list, key) => {
  let array = []

  list.forEach((item) => {
    array.push({
      [key]: item[key][0],
      molecule: item?.Molecule[0],
      values: { ...item },
      skuTags: item?.skuTags,
      form: item?.form,
      strength: item?.strength,
    })
  })

  var mergedObj = array.reduce((acc, obj) => {
    if (acc[obj[key]]) {
      acc[obj[key]].values = Array.isArray(acc[obj[key]].values)
        ? acc[obj[key]].values.concat({ ...obj.values })
        : [acc[obj[key]].values].concat({ ...obj.values })
    } else {
      acc[obj[key]] = { ...obj }
    }

    return acc
  }, {})

  let output = []
  for (let prop in mergedObj) {
    output.push(mergedObj[prop])
  }

  return output
}
