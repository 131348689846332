import React, { Fragment } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import SearchResultRow from "./SearchResults/SearchResultRow"
import styles from "./utils/search.module.scss"

const SearchResults = ({
  searchResults,
  isExactMatch,
  onSelect,
  setSearchResults,
  module,
  setSearchQuery,
}) => {
  const otherMedicineMessage =
    module === "request" ? `, or choose "Other Medicine"` : ""

  return (
    <section className={classNames(styles["searchResult"])}>
      <ol className={classNames({ "is-hidden": !searchResults.length })}>
        {isExactMatch && (
          <li className={classNames(styles["errorMessage"], "helper")}>
            {!module ? (
              <Fragment>
                <span className="has-text-weight-bold">
                  If you cannot find your prescription medicines,
                </span>{" "}
                please feel free to:
                <div className="content">
                  <ul>
                    <li>
                      Order it through{" "}
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => {
                          navigate(`/flexmed/order/mechanics`)
                        }}
                      >
                        FlexMed Cashless
                      </a>{" "}
                      or,
                    </li>
                    <li>
                      Purchase it from your most convenient pharmacy and have it
                      reimbursed through{" "}
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => {
                          navigate(`/flexmed/reimburse/mechanics`)
                        }}
                      >
                        FlexMed Reimbursement
                      </a>
                    </li>
                  </ul>
                </div>
              </Fragment>
            ) : (
              <span className="has-text-danger">
                <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                <b>Item cannot be found.</b> Please type again, choose the
                closest possible match{otherMedicineMessage}.
              </span>
            )}
          </li>
        )}
        {searchResults.slice(0, 10).map((result) => (
          <SearchResultRow
            result={result}
            handleClick={() => {
              if (onSelect) onSelect(result)
              setSearchResults([])
              setSearchQuery("")
            }}
            module={module}
          />
        ))}
      </ol>
    </section>
  )
}
export default SearchResults
