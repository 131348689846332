import React from "react"
import RxIndicator from "../../Elements/RxIndicator"
import { formatPrice } from "../../../services/general"
import { generatePrice } from "../../Epharmacy/services/computations"

const RefillSearchResultRow = ({ result, handleClick, searchType }) => (
  <li onClick={() => handleClick()}>
    <div className="is-flex has-text-weight-bold">
      <div>
        {searchType === "drug" ? result?.drugName : result.productTitle}
        {result?.description ? (
          <p className="help has-text-weight-normal is-italic">
            {result.description}
          </p>
        ) : null}
      </div>
      {result.rxRequired && result.productTitle && <RxIndicator />}
    </div>
    {searchType !== "drug" && (
      <div className="is-size-7 mt-1">
        {result.productTitle !== "Partner Request (MedGrocer)" &&
        result.productTitle !== "MedGrocer Wellness Patient"
          ? ` Php ${formatPrice({ priceString: generatePrice(result) })}`
          : ""}
      </div>
    )}
  </li>
)

export default RefillSearchResultRow
